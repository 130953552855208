@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: -webkit-fill-available;
}
body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    min-height: -webkit-fill-available;
  }
}

body .gg {
  @apply font-serif text-white;
}

.step {
  @apply transition z-10;
}

.container-step-full {
  @apply bg-black bg-paper-black transition duration-500;
}

.container-step-end {
  @apply xs:h-auto md:h-full;
}

.step.current {
  @apply z-10 transition;
}

.step .description {
  @apply xs:text-base italic text-blue-dark;
}

.step .step-left {
  @apply transform transition duration-500 pointer-events-auto;
}

.step.current .step-left {
  @apply transform xs:translate-y-0 lg:translate-x-0 transition duration-500;
}

.step .step-none {
  @apply transform -translate-x-full transition duration-500 pointer-events-auto;
}

.step.current .step-none {
  @apply transform translate-x-0 transition duration-500;
}

article h2 {
  @apply xs:text-xl xs:leading-5 lg:text-2xl font-bold mb-4 text-red;
}

article img,
article figure {
  @apply max-w-full my-2;
}

article figcaption {
  @apply text-sm italic text-red;
}

.gg-0 {
  @apply pointer-events-auto;
}

.gg-6 {
  /*@apply h-screen;*/
}

.legende img {
  max-width: 60px;
  height: fit-content;
}

p {
  @apply mb-4;
}

/* Mapbox */
.mapboxgl-map {
  position: absolute !important;
}
#comparison-container {
  position: fixed;
  height: 100vh;
}

.mapboxgl-popup {
  width: 200px;
}

.mapboxgl-popup-close-button {
  right: 10px;
  top: 5px;
  @apply hidden;
}

.mapboxgl-popup-content {
  @apply bg-brown-dark;
}

.mapboxgl-popup-content h2 {
  @apply font-serif  text-blue-dark;
  line-height: 15px;
  margin-bottom: 5px;
}

.mapboxgl-popup-content img {
  cursor: pointer;
}

.mapboxgl-compare .compare-swiper-vertical {
  @apply bg-blue;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #dccec3;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #dccec3;
}
.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: #dccec3;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #dccec3;
}

/* Open Sea Dragon */
.openseadragon-container :focus {
  outline: none;
}

/* MODAL */
.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 80%;
  padding: 5px;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.osd-popup-content {
  width: 95%;
  height: 95%;
}

/* Parchemin */
.parchment {
  position: absolute;
  z-index: -1;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px, 0 0 125px #e2ccbc inset;
  filter: url(#wavy2);
  background-image: url('./assets/bg-brown-papper-light.jpg');
  background-attachment: scroll !important;
  background-size: cover;
}

.parchment-mobile {
  position: absolute;
  z-index: -1;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*box-shadow: 2px 3px 20px black, 0 0 125px #c9ab96 inset;*/
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px, 0 0 125px #e2ccbc inset;
  background: white;
  background-image: url('./assets/bg-brown-papper-light.jpg');
  background-attachment: scroll !important;
  background-size: cover;
}

.parchment-safari {
  position: absolute;
  z-index: -1;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px, 0 0 125px #e2ccbc inset;
  background-image: url('./assets/bg-brown-papper-light.jpg');
  background-attachment: scroll !important;
  background-size: cover;
}

.parchment-black {
  position: absolute;
  z-index: -1;
  display: flex;
  width: 100%;
  height: 100%;
  margin-left: -3px;
  margin-top: -3px;
  /* center page with absolute position */
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0);
  /*box-shadow: 2px 3px 20px black, 0 0 125px black inset;*/
  background: white;
  /* v2.1 : borders effect with SVG : try to play with scale to change them */
  filter: url(#wavy2);
  /* v2.2 : Noise added for a vellum paper effect */
  background-color: rgb(0, 0, 0);
  background-attachment: scroll !important;
  background-size: cover;
}

.bg-paper-black {
  background: url('./assets/bg-black-papper-repeatable.png');
  background-attachment: scroll !important;
  background-size: contain;
}

.bg-paper-brown {
  background-image: url('./assets/bg-brown-papper-light.jpg');
  background-attachment: scroll !important;
  background-size: cover;
}
